import React from 'react'
import { HashRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom"
import css from './index.module.scss'
import Head from './head'
import Menu from './menu'
import routeConfig from './routeConfig'


export default class Zhcn extends React.Component {
  render() {
    return (<div className={`${css.container} clearfix`}>
      <Head />
      <Menu />
      <div className={css.content}>
        <Switch>
          {routeConfig.map(v => {
            const Com = v.component
            return <Route key={v.path} path={`/zh-cn/${v.path}`} render={props => <Com {...props} />} />
          })}
        </Switch>
      </div>
    </div>)
  }
}