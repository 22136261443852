import React from 'react'
import { HashRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import './assets/css/normalize.css'
import './assets/css/restyle.css'
import En from './en'
import Zhcn from './zh-cn'

class Doc extends React.Component {
  render() {
    return (<Router>
      <Switch>
        <Route path="/en">
          <En />
        </Route>
        <Route path="/zh-cn">
          <Zhcn />
        </Route>
        <Route path="/">
          <Redirect to="/en" />
        </Route>
      </Switch>
    </Router>)
  }
}

export default Doc
