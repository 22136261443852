import React from 'react'
import { fromLayout, button } from '../../../../six-px/px'
import css from './index.module.scss'

export default class FromLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      vertical: false,
      col: false,
      size: false,
    }
  }

  setDefault = () => {
    this.setState({
      error: false,
      vertical: false,
      col: false,
    })
  }

  setError = () => {
    this.setState({ error: true })
  }

  setVertical = () => {
    this.setState({ vertical: true })
  }

  setCol = (col) => {
    this.setState({ col })
  }

  setSize = (size) => {
    this.setState({ size })
  }

  render() {

    const { error, vertical, col, size } = this.state

    return (<div>
      <h2>表单布局</h2>
      <p className={css.des}>
        这里只是表单的布局，没有input,checkbox...
        <br />
        表单内容高度: sm 24px, default 30px, lg 36px.
        <br />
        当消息显示当时候，表单内容当高度不会变化，这个非常重要
        <br />
        所以它可以垂直布局，多列布局，这是依赖于高度不会变化。
        </p>

      <div className="btn-group">
        <button className="btn btn-primary" onClick={this.setDefault}>默认</button>
        <button className="btn btn-primary" onClick={this.setError}>错误</button>
        <button className="btn btn-primary" onClick={this.setVertical}>垂直</button>
        <button className="btn btn-primary" onClick={() => { this.setCol(2) }}>2列</button>
        <button className="btn btn-primary" onClick={() => { this.setCol(3) }}>3列</button>
        <button className="btn btn-primary" onClick={() => { this.setSize('sm') }}>小号</button>
        <button className="btn btn-primary" onClick={() => { this.setSize('lg') }}>大号</button>
      </div>
      <div className={css.content}>
        <div className={`form-layout clearfix ${vertical ? 'form-vertical' : ''} ${col ? 'form-col-' + col : ''} ${size ? 'form-' + size : ''}`}>
          <div className={`form-item ${error ? 'form-error' : ''}`}>
            <div className="form-label">Lable:</div>
            <div className="from-content">
              <input type="text" className={`${css.inputdemo} ${css[size]}`} />
              {error ? <span className="form-message">Error messages</span> : <span className="form-message">Helper message</span>}
            </div>
          </div>
          <div className={`form-item ${error ? 'form-error' : ''}`}>
            <div className="form-label">Lable:</div>
            <div className="from-content">
              <input type="text" className={`${css.inputdemo} ${css[size]}`} />
              {error ? <span className="form-message">Error messages</span> : null}
            </div>
          </div>
          <div className="form-item form-error">
            <div className="form-label">Lable:</div>
            <div className="from-content">
              <input type="text" className={`${css.inputdemo} ${css[size]}`} />
              <span className="form-message">Error messages</span>
            </div>
          </div>
          <div className={`form-item ${error ? 'form-error' : ''}`}>
            <div className="form-label">Lable:</div>
            <div className="from-content">
              <input type="text" className={`${css.inputdemo} ${css[size]}`} />
              {error ? <span className="form-message">Error messages</span> : null}
            </div>
          </div>
        </div>
      </div>
    </div>)
  }
}