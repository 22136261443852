import React from 'react'
import { checkbox, pannel } from '../../../../six-px/px'
import css from './index.module.scss'
import Group from './group'

export default class Checkbox extends React.Component {

  render() {
    return (<div className={css.container}>
      <Group size="sm" />
      <Group />
      <Group size="lg" />

      <div className="panel panel-fixed">
        <div className="panel-head">
          <div className="panel-title">
            @media demo
          </div>
          <div className="panel-extra">
            <span className={css['panel-extra']}>You can adjust the browser width</span>
          </div>
        </div>
        <div className={`panel-body`}>
        <label
            className="checkbox-wrapper checkbox-lg checkbox-md-md checkbox-sm-sm"
          >
            <span className="checkbox">
              <input type="checkbox" className="checkbox-input"/>
              <span className="checkbox-inner"></span>
            </span>
            <span>checkbox</span>
          </label>
          <label
            className="checkbox-wrapper checkbox-checked checkbox-lg checkbox-md-md checkbox-sm-sm"
          >
            <span className="checkbox">
              <input type="checkbox" className="checkbox-input"/>
              <span className="checkbox-inner"></span>
            </span>
            <span>checkbox</span>
          </label>
        </div>
      </div>

    </div>)
  }
}