import React from 'react'
import { Link } from "react-router-dom"
import css from './index.module.scss'

export default class Menu extends React.Component {

  render() {

    return (<div className={css.menu}>
      <ul>
        <li><Link to="/zh-cn">开始</Link></li>
        <li><Link to="/zh-cn/button">按钮</Link></li>
        <li><Link to="/zh-cn/form-layout">表单布局</Link></li>
      </ul>
    </div>)

  }

}