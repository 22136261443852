import React from 'react'
import { listBlock, button, panel } from '../../../../six-px/px'
import css from './index.module.scss'
import img from '../../../assets/img/16*9.jpg'

export default class List extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      space: 12,
      spaceAll: true,
      block: 4,
      border: true,
    }
  }

  setDefault = () => {
    this.setState({
      space: 12,
      spaceAll: true,
      block: 4,
      border: true,
    })
  }

  toggleSpaceAll = () => {
    this.setState(old => ({ spaceAll: !old.spaceAll }))
  }

  toggleBorder = () => {
    this.setState(old => ({ border: !old.border }))
  }

  setBlock = (block) => {
    let newBlock = block + 1
    if (newBlock > 6) {
      newBlock = 2
    }

    this.setState({ block: newBlock })
  }

  setSpace = (space) => {
    let newSpace = space + 6
    if (newSpace > 30) {
      newSpace = 6
    }

    this.setState({ space: newSpace })
  }

  getList = () => {
    let list = []
    for (let i = 1; i < 13; i++) {
      list.push(<li key={i}>
        <div className="block">
          <img src={img} alt="img" />
          <span>{i}</span>
        </div>
      </li>)
    }

    return list
  }

  render() {

    const { space, spaceAll, block, border } = this.state

    return (<div className={css.container}>
      <div className={css.handle}>
        <div className="btn-group">
          <button className="btn btn-primary" onClick={this.setDefault}>Default</button>
          <button className="btn btn-primary" onClick={this.toggleSpaceAll}>Toggle Space All</button>
          <button className="btn btn-primary" onClick={this.toggleBorder}>Toggle Border</button>
          <button className="btn btn-primary" onClick={() => { this.setBlock(block) }}>Block {block}</button>
          <button className="btn btn-primary" onClick={() => { this.setSpace(space) }}>Space {space}</button>
        </div>
      </div>
      <div className={`${css.content} ${css.shadow}`}>
        <ul className={`list-block-${block} ${space ? `list-space-${space}${spaceAll ? 'all' : ''}` : ''} ${border ? '' : 'no-border'} clearfix`}>
          {this.getList()}
        </ul>
      </div>
      <div className="panel panel-fixed">
        <div className="panel-head">
          <div className="panel-title">
            @media demo
          </div>
          <div className="panel-extra">
            <span className={css['panel-extra']}>You can adjust the browser width</span>
          </div>
        </div>
        <div className={`panel-body ${css['panel-body']}`}>
          <ul className={`list-block-6 list-block-5-md list-block-4-sm list-space-12all clearfix`}>
            {this.getList()}
          </ul>
        </div>
      </div>
    </div>)
  }
}