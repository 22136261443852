import React from 'react'
import { Link } from "react-router-dom"
import css from './index.module.scss'

export default class Menu extends React.Component {

  render() {

    return (<div className={css.menu}>
      <ul>
        <li><Link to="/en">Getting Started</Link></li>
        <li><Link to="/en/button">Button</Link></li>
        <li><Link to="/en/panel">Panel</Link></li>
        <li><Link to="/en/list-block">ListBlock</Link></li>
        <li><Link to="/en/form-layout">FormLayout</Link></li>
        <li><Link to="/en/input">Input</Link></li>
        <li><Link to="/en/radio">Radio</Link></li>
        <li><Link to="/en/checkbox">Checkbox</Link></li>
      </ul>
    </div>)

  }

}