import Started from './pages/started'
import Button from './pages/button'
import ListBlock from './pages/listBlock'
import FormLayout from './pages/formLayout'
import Panel from './pages/panel'
import Input from './pages/input'
import Radio from './pages/radio'
import Checkbox from './pages/checkbox'

export default [
  {
    path: 'button',
    component: Button
  },
  {
    path: 'form-layout',
    component: FormLayout
  },
  {
    path: 'panel',
    component: Panel
  },
  {
    path: 'input',
    component: Input
  },
  {
    path: 'list-block',
    component: ListBlock
  },
  {
    path: 'radio',
    component: Radio
  },
  {
    path: 'checkbox',
    component: Checkbox
  },
  {
    path: '',
    component: Started
  },
]