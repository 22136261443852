import React from 'react'

export default class Started extends React.Component {

  render() {

    return (<div>
      <h2>依赖</h2>
      <ol>
        <li>normalize.css</li>
        <li>box-sizing: border-box;</li>
      </ol>
      <h2>使用</h2>
      <ul>
        <li>{`import ${'{'} button ${'}'} from 'six-px'`}</li>
        <li>右键点击查看相关Demo代码</li>
      </ul>
      <h2>为什么是 six-px?</h2>
      <p>页面和元素的间距以及尺寸都是按照6的倍数设计的。</p>
      <h2>特色</h2>
      <p>设计的各个组件不进行相互依赖，可以和其它UI搭配使用。</p>
    </div>)

  }

}