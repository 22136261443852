import React from 'react'
import { button, pannel } from '../../../../six-px/px'
import css from './index.module.scss'

export default class Button extends React.Component {

  render() {

    return (<div>
      <h2>Button</h2>
      <div className={css.buttons}>
        <button className="btn btn-primary btn-xs">size xs 18px</button>
        <button className="btn btn-primary btn-sm">size sm 24px</button>
        <button className="btn btn-primary">default 30px</button>
        <button className="btn btn-primary btn-lg">size lg 36px</button>
        <button className="btn btn-primary btn-xl">size xl 42px</button>
      </div>

      <div className={css.buttons}>
        <button className="btn btn-primary">Primary</button>
        <button className="btn btn-secondary">Secondary</button>
        <button className="btn btn-success">Success</button>
        <button className="btn btn-info">Info</button>
        <button className="btn btn-warning">Warning</button>
        <button className="btn btn-danger">Danger</button>
        <button className="btn btn-light">Light</button>
        <button className="btn btn-dark">Dark</button>
        <button className="btn btn-link">Link</button>
      </div>

      <div className={`${css.buttons} ${css.ghost}`}>
        <button className="btn btn-outline-primary">Primary</button>
        <button className="btn btn-outline-secondary">Secondary</button>
        <button className="btn btn-outline-success">Success</button>
        <button className="btn btn-outline-info">Info</button>
        <button className="btn btn-outline-warning">Warning</button>
        <button className="btn btn-outline-danger">Danger</button>
        <button className="btn btn-outline-light">Light</button>
        <button className="btn btn-outline-dark">Dark</button>
      </div>

      <div className={`${css.buttons} ${css.ghost}`}>
        <button className="btn btn-outline-primary ghost">Primary</button>
        <button className="btn btn-outline-secondary ghost">Secondary</button>
        <button className="btn btn-outline-success ghost">Success</button>
        <button className="btn btn-outline-info ghost">Info</button>
        <button className="btn btn-outline-warning ghost">Warning</button>
        <button className="btn btn-outline-danger ghost">Danger</button>
        <button className="btn btn-outline-light ghost">Light</button>
        <button className="btn btn-outline-dark ghost">Dark</button>
      </div>

      <div className={css.buttons}>
        <button className="btn btn-primary disabled">Primary</button>
        <button className="btn btn-secondary disabled">Secondary</button>
        <button className="btn btn-success disabled">Success</button>
        <button className="btn btn-info disabled">Info</button>
        <button className="btn btn-warning disabled">Warning</button>
        <button className="btn btn-danger disabled">Danger</button>
        <button className="btn btn-light disabled">Light</button>
        <button className="btn btn-dark disabled">Dark</button>
      </div>

      <div className={css.groups}>
        <div className="btn-group btn-group-xs">
          <button className="btn btn-primary">A</button>
          <button className="btn btn-primary">B</button>
          <button className="btn btn-primary">C</button>
        </div>
        <div className="btn-group btn-group-sm">
          <button className="btn btn-primary">A</button>
          <button className="btn btn-primary">B</button>
          <button className="btn btn-primary">C</button>
        </div>
        <div className="btn-group">
          <button className="btn btn-primary">A</button>
          <button className="btn btn-primary">B</button>
          <button className="btn btn-primary">C</button>
        </div>
        <div className="btn-group btn-group-lg" style={{ padding: '10px', backgroundColor: '#000' }}>
          <button className="btn btn-outline-primary ghost">A</button>
          <button className="btn btn-outline-primary ghost">B</button>
          <button className="btn btn-outline-primary ghost">C</button>
        </div>
        <div className="btn-group btn-group-lg">
          <button className="btn btn-outline-primary">A</button>
          <button className="btn btn-outline-primary">B</button>
          <button className="btn btn-outline-primary">C</button>
        </div>
        <div className="btn-group btn-group-xl">
          <button className="btn btn-primary">A</button>
          <button className="btn btn-primary">B</button>
          <button className="btn btn-primary">C</button>
        </div>
      </div>

      <div className={css.groups}>
        <div className="btn-group-vertical btn-group-xs">
          <button className="btn btn-primary">A</button>
          <button className="btn btn-primary">B</button>
          <button className="btn btn-primary">C</button>
        </div>
        <div className="btn-group-vertical btn-group-sm">
          <button className="btn btn-primary">A</button>
          <button className="btn btn-primary">B</button>
          <button className="btn btn-primary">C</button>
        </div>
        <div className="btn-group-vertical">
          <button className="btn btn-secondary">A</button>
          <button className="btn btn-secondary">B</button>
          <button className="btn btn-secondary">C</button>
        </div>
        <div className="btn-group-vertical btn-group-md">
          <button className="btn btn-success">A</button>
          <button className="btn btn-success">B</button>
          <button className="btn btn-success">C</button>
        </div>
        <div className="btn-group-vertical btn-group-lg">
          <button className="btn btn-primary">A</button>
          <button className="btn btn-primary">B</button>
          <button className="btn btn-primary">C</button>
        </div>
        <div className="btn-group-vertical btn-group-xl">
          <button className="btn btn-primary">A</button>
          <button className="btn btn-primary">B</button>
          <button className="btn btn-primary">C</button>
        </div>
      </div>
      <div className="panel panel-fixed">
        <div className="panel-head">
          <div className="panel-title">
            @media demo
          </div>
          <div className="panel-extra">
            <span className={css['panel-extra']}>You can adjust the browser width</span>
          </div>
        </div>
        <div className={`panel-body`}>
          <div className={css.buttons}>
            <button className="btn btn-primary btn-xl btn-md-md btn-xs-sm">Primary</button>
            <button className="btn btn-outline-primary btn-lg btn-md-md btn-sm-sm">Primary</button>

          </div>
          <div className="btn-group-vertical btn-group-xl btn-group-md-md btn-group-xs-sm">
            <button className="btn btn-primary">A</button>
            <button className="btn btn-primary">B</button>
            <button className="btn btn-primary">C</button>
          </div>
        </div>
      </div>
    </div>)

  }

}