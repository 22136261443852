import React from 'react'
import { fromLayout, button, input, pannel } from '../../../../six-px/px'
import css from './index.module.scss'

export default class FromLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      vertical: false,
      col: false,
      size: false,
    }
  }

  setDefault = () => {
    this.setState({
      error: false,
      vertical: false,
      col: false,
      size: false,
    })
  }

  setError = () => {
    this.setState({ error: true })
  }

  setVertical = () => {
    this.setState({ vertical: true })
  }

  setCol = (col) => {
    this.setState({ col })
  }

  setSize = (size) => {
    this.setState({ size })
  }

  getItems = () => {
    let list = []
    for (let i = 1; i < 9; i++) {
      list.push(<div key={i} className="form-item">
        <div className="form-label">Lable:</div>
        <div className="from-content">
          <div className="input input-lg input-md-md input-sm-sm">
            <input type="text" value="input text" />
          </div>
          <span className="form-message">Helper message</span>
        </div>
      </div>)
    }

    return list
  }

  render() {

    const { error, vertical, col, size } = this.state

    return (<div>
      <h2>Form Layout</h2>
      <p className={css.des}>
        Here's just the layout of the form, no input, checkbox...
            <br />
        Form content height: sm 24px, default 30px, lg 36px.
            <br />
        When the message is displayed, the content of the form remains unchanged when the height is high, which is very important.
            <br />
        So it can be vertical layout, multi-column layout, which depends on the height will not change.
      </p>

      <div className="btn-group">
        <button className="btn btn-primary" onClick={this.setDefault}>Default</button>
        <button className="btn btn-primary" onClick={this.setError}>Error</button>
        <button className="btn btn-primary" onClick={this.setVertical}>Vertical</button>
        <button className="btn btn-primary" onClick={() => { this.setCol(2) }}>Column2</button>
        <button className="btn btn-primary" onClick={() => { this.setCol(3) }}>Column3</button>
        <button className="btn btn-primary" onClick={() => { this.setSize('sm') }}>Small</button>
        <button className="btn btn-primary" onClick={() => { this.setSize('lg') }}>Large</button>
      </div>
      <div className={css.content}>
        <div className={`form-layout clearfix ${vertical ? 'form-vertical' : ''} ${col ? 'form-col-' + col : ''} ${size ? 'form-' + size : ''}`}>
          <div className={`form-item ${error ? 'form-error' : ''}`}>
            <div className="form-label">Lable:</div>
            <div className="from-content">
              <div className={`input ${size ? 'input-' + size : ''}`}>
                <input type="text" value="input text" />
              </div>
              {error ? <span className="form-message">Error messages</span> : <span className="form-message">Helper message</span>}
            </div>
          </div>
          <div className={`form-item ${error ? 'form-error' : ''}`}>
            <div className="form-label">Lable:</div>
            <div className="from-content">
              <div className={`input ${size ? 'input-' + size : ''}`}>
                <input type="text" value="input text" />
              </div>
              {error ? <span className="form-message">Error messages</span> : null}
            </div>
          </div>
          <div className="form-item form-error">
            <div className="form-label">Lable:</div>
            <div className="from-content">
              <div className={`input ${size ? 'input-' + size : ''}`}>
                <input type="text" value="input text" />
              </div>
              <span className="form-message">Error messages</span>
            </div>
          </div>
          <div className={`form-item ${error ? 'form-error' : ''}`}>
            <div className="form-label">Lable:</div>
            <div className="from-content">
              <div className={`input ${size ? 'input-' + size : ''}`}>
                <input type="text" value="input text" />
              </div>
              {error ? <span className="form-message">Error messages</span> : null}
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-fixed">
        <div className="panel-head">
          <div className="panel-title">
            @media demo
          </div>
          <div className="panel-extra">
            <span className={css['panel-extra']}>You can adjust the browser width</span>
          </div>
        </div>
        <div className={`panel-body ${css['panel-body']}`}>
          <div className="form-layout form-col-4 form-col-3-md form-col-2-sm form-lg form-md-md form-sm-sm clearfix">
            {this.getItems()}
          </div>
        </div>
      </div>
    </div>)
  }
}