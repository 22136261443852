import React from 'react'
import { checkbox } from '../../../../six-px/px'
import css from './index.module.scss'

export default class CheckboxGroup extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      list: [
        { value: 1, name: 'a' },
        { value: 2, name: 'b', checked: true },
        { value: 3, name: 'c', disabled: true },
        { value: 4, name: 'c', checked: true, disabled: true },
      ]
    }
  }

  getGroup = () => {
    const { list } = this.state
    const { size } = this.props

    return list.map(v => {
      return <label
        key={v.value}
        className={`checkbox-wrapper ${size ? 'checkbox-' + size : ''} ${v.checked ? 'checkbox-checked' : ''} ${v.disabled ? 'checkbox-disabled' : ''}`}
      >
        <span className="checkbox">
          <input type="checkbox" className="checkbox-input" onClick={(e) => { this.onCheck(e,v) }} />
          <span className="checkbox-inner"></span>
        </span>
        <span>{v.name}</span>
      </label>
    })
  }

  onCheck = (e,v) => {
    // e.preventDefault()
    // console.log('checkbox on click',v)

    if(v.disabled) {
      return
    }

    this.setState(old => {
      v.checked = !v.checked
      return { list: old.list }
    })

  }

  render() {
    return (
      <div className={css.content}>
        {this.getGroup()}
      </div>
    )
  }
}