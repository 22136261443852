import React from 'react'
import { input, fromLayout } from '../../../../six-px/px'
import css from './index.module.scss'

export default class Panel extends React.Component {

  render() {
    return (<div className={css.container}>
      <div className="form-layout form-sm form-col-2">
        <div className="form-item">
          <div className="from-content">
            <div className="input input-sm">
              <input type="text" value="input text" />
            </div>
            <span className="form-message">Helper message</span>
          </div>
        </div>
        <div className="form-item">
          <div className="from-content">
            <div className="input input-border input-sm">
              <input type="text" value="input text" />
            </div>
            <span className="form-message">Helper message</span>
          </div>
        </div>
      </div>

      <div className="form-layout form-col-2">
        <div className="form-item">
          <div className="from-content">
            <div className="input input-error">
              <input type="text" value="input text" />
            </div>
            <span className="form-message">Error messages</span>
          </div>
        </div>
        <div className="form-item">
          <div className="from-content">
            <div className="input input-border input-error">
              <input type="text" value="input text" />
            </div>
            <span className="form-message">Error messages</span>
          </div>
        </div>
      </div>

      <div className="form-layout form-col-2">
        <div className="form-item form-error">
          <div className="from-content">
            <div className="input">
              <input type="text" value="input text" />
            </div>
            <span className="form-message">Error messages</span>
          </div>
        </div>
        <div className="form-item form-error">
          <div className="from-content">
            <div className="input input-border">
              <input type="text" value="input text" />
            </div>
            <span className="form-message">Error messages</span>
          </div>
        </div>
      </div>

      <div className="form-layout form-lg form-col-2">
        <div className="form-item">
          <div className="from-content">
            <div className="input input-lg ">
              <input type="text" value="input text" />
            </div>
            <span className="form-message">Helper message</span>
          </div>
        </div>
        <div className="form-item">
          <div className="from-content">
            <div className="input input-border input-lg">
              <input type="text" value="input text" />
            </div>
            <span className="form-message">Helper message</span>
          </div>
        </div>
      </div>
    </div>)
  }
}