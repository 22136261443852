import Started from './pages/started'
import Button from './pages/button'
import FormLayout from './pages/formLayout'

export default [
  {
    path: 'button',
    component: Button
  },
  {
    path: 'form-layout',
    component: FormLayout
  },
  {
    path: '',
    component: Started
  },
]