import React from 'react'
import { radio, pannel } from '../../../../six-px/px'
import css from './index.module.scss'
import RadioGroup from './group'

export default class Radio extends React.Component {

  render() {
    return (<div className={css.container}>
      <RadioGroup size="sm" />
      <RadioGroup />
      <RadioGroup size="lg" />
      <RadioGroup disabled={true} />

      <div className="panel panel-fixed">
        <div className="panel-head">
          <div className="panel-title">
            @media demo
          </div>
          <div className="panel-extra">
            <span className={css['panel-extra']}>You can adjust the browser width</span>
          </div>
        </div>
        <div className={`panel-body`}>
        <label className="radio-wrapper radio-lg radio-md-md radio-sm-sm radio-checked" >
            <span className="radio">
              <input type="radio" className="radio-input" />
              <span className="radio-inner"></span>
            </span>
            <span>Radio</span>
          </label>
          <label className="radio-wrapper radio-lg radio-md-md radio-sm-sm" >
            <span className="radio">
              <input type="radio" className="radio-input" />
              <span className="radio-inner"></span>
            </span>
            <span>Radio</span>
          </label>
        </div>
      </div>

    </div>)
  }
}