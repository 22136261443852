import React from 'react'
import { radio } from '../../../../six-px/px'
import css from './index.module.scss'

export default class Radio extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      value: 1
    }
  }

  getGroup = () => {
    const { value } = this.state
    const lis = [1, 2]
    const { size, disabled } = this.props

    return lis.map(v => {
      return <label
        key={v}
        className={`radio-wrapper ${size ? 'radio-' + size : ''} ${value === v ? 'radio-checked' : ''} ${disabled ? 'radio-disabled' : ''}`}
      >
        <span className="radio">
          <input type="radio" className="radio-input" onClick={() => { this.onCheck(v, disabled) }} />
          <span className="radio-inner"></span>
        </span>
        <span>Radio</span>
      </label>
    })
  }

  onCheck = (v, disabled) => {
    if(disabled) {
      return false
    }
    this.setState({ value: v })
  }

  render() {
    return (
      <div className={css.content}>
        {this.getGroup()}
      </div>
    )
  }
}