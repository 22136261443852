import React from 'react'
import css from './index.module.scss'

export default class Started extends React.Component {

  render() {

    return (<div className={css.container}>
      <h2>Introduction</h2>
      <p>six-px is a PC CSS Framework.</p>
      <h2>Dependent</h2>
      <ul>
        <li>normalize.css</li>
        <li>box-sizing: border-box;</li>
      </ul>
      <h2>Use</h2>
      <ul>
        <li>{`import ${'{'} button ${'}'} from 'six-px'`}</li>
        <li>Right-click demo to view the code</li>
      </ul>
      <h2>Why six-px?</h2>
      <p>Spacing and size step are 6px.</p>
      <h2>Special</h2>
      <p>Each component can be used independently without interdependence.</p>
      <h2>Size</h2>
      <ul>
        <li>xs:18px</li>
        <li>sm:24px</li>
        <li>md:30px(default)</li>
        <li>lg:36px</li>
        <li>xl:42px</li>
      </ul>
      <h2>@media</h2>
      <p>Large desktops first</p>
      <p>lg: large desktops, 1200px and up</p>
      <p>md: desktops, 1200px down</p>
      <p>sm: tablets, 992px down</p>
      <p>@media (max-width: 1199.98px) {`${'{'}/*md*/${'}'}`}</p>
      <p>@media (max-width: 991.98px) {`${'{'}/*sm*/${'}'}`}</p>

      <h2>Size and @media are together</h2>
      <p>First size is component size, second size is device.</p>
      <p>class="input-sm-md" mean sm:input size height 24px, md:desktops 1200px down</p>
    </div>)

  }

}