import React from 'react'
import { panel, button, input } from '../../../../six-px/px'
import css from './index.module.scss'

export default class Panel extends React.Component {

  render() {

    return (<div className={css.container}>
      <div className={`${css.content}`}>

        <div className="panel">
          <div className="panel-head">
            <div className="panel-title">
              Panel Title
              </div>
            <div className="panel-extra">
            </div>
          </div>
          <div className="panel-body">
            Panel Body
            </div>
        </div>

        <div className="panel">
          <div className="panel-head">
            <div className="panel-title">
              Panel Title
              </div>
            <div className="panel-extra">
              <div className={css.search}>
                <div className={`input input-sm ${css.inputdemo}`}>
                  <input type="text" value="input text" />
                </div>
                <button className="btn btn-outline-primary btn-sm" style={{marginLeft: '6px'}}>Search</button>
              </div>
              <div className={css.add}>
                <button className="btn btn-primary btn-sm">Add</button>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <ul>
              <li key="1">List 1</li>
              <li key="2">List 2</li>
              <li key="3">List 3</li>
              <li key="4">List 4</li>
            </ul>
          </div>
        </div>

        <div className="panel panel-dark">
          <div className="panel-head">
            <div className="panel-title">
              Panel Title
              </div>
            <div className="panel-extra">
            </div>
          </div>
          <div className="panel-body">
            Panel Body
            </div>
        </div>

        <div className="panel panel-dark">
          <div className="panel-head">
            <div className="panel-title">
              Panel Title
              </div>
            <div className="panel-extra">
              <div className={css.search}>
                <div className={`input input-border input-sm ${css.inputdemo}`}>
                  <input type="text" value="input text" />
                </div>
                <button className="btn btn-primary btn-sm">Search</button>
              </div>
              <div className={css.add}>
                <button className="btn btn-primary btn-sm">Add</button>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <ul>
              <li key="1">List 1</li>
              <li key="2">List 2</li>
              <li key="3">List 3</li>
              <li key="4">List 4</li>
            </ul>
          </div>
        </div>

        <div className="panel panel-fixed">
          <div className="panel-head">
            <div className="panel-title">
              Panel Title
              </div>
          </div>
          <div className="panel-body">
            Panel Body
            </div>
        </div>
        <div className="panel panel-fixed">
          <div className="panel-head">
            <div className="panel-title">
              Panel Title
              </div>
            <div className="panel-extra">
              <div className={css.search}>
                <div className={`input input-border input-sm ${css.inputdemo}`}>
                  <input type="text" value="input text" />
                </div>
                <button className="btn btn-primary btn-sm">Search</button>
              </div>
              <div className={css.add}>
                <button className="btn btn-primary btn-sm">Add</button>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <ul>
              <li key="1">List 1</li>
              <li key="2">List 2</li>
              <li key="3">List 3</li>
              <li key="4">List 4</li>
            </ul>
          </div>
        </div>

      </div>

      <div className={`${css.content} ${css.gray}`}>

        <div className="panel panel-light clearfix">
          <div className="panel-head">
            <div className="panel-title">
              Panel Title
              </div>
            <div className="panel-extra">
            </div>
          </div>
          <div className="panel-body">
            Panel Body
            </div>
        </div>

        <div className="panel panel-light clearfix">
          <div className="panel-head">
            <div className="panel-title">
              Panel Title
              </div>
            <div className="panel-extra">
              <div className={css.search}>
                <div className={`input input-sm ${css.inputdemo}`}>
                  <input type="text" value="input text" />
                </div>
                <button className="btn btn-outline-primary btn-sm" style={{marginLeft: '6px'}}>Search</button>
              </div>
              <div className={css.add}>
                <button className="btn btn-outline-primary btn-sm">Add</button>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <ul>
              <li key="1">List 1</li>
              <li key="2">List 2</li>
              <li key="3">List 3</li>
              <li key="4">List 4</li>
            </ul>
          </div>
        </div>

        <div className="panel">
          <div className="panel-head">
            <div className="panel-title">
              Panel Title
              </div>
            <div className="panel-extra">
            </div>
          </div>
          <div className="panel-body">
            Panel Body
            </div>
        </div>

        <div className="panel">
          <div className="panel-head">
            <div className="panel-title">
              Panel Title
              </div>
            <div className="panel-extra">
              <div className={css.search}>
                <div className={`input input-border input-sm ${css.inputdemo}`}>
                  <input type="text" value="input text" />
                </div>
                <button className="btn btn-primary btn-sm">Search</button>
              </div>
              <div className={css.add}>
                <button className="btn btn-primary btn-sm">Add</button>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <ul>
              <li key="1">List 1</li>
              <li key="2">List 2</li>
              <li key="3">List 3</li>
              <li key="4">List 4</li>
            </ul>
          </div>
        </div>

        <div className="panel panel-fixed">
          <div className="panel-head">
            <div className="panel-title">
              Panel Title
              </div>
            <div className="panel-extra"></div>
          </div>
          <div className="panel-body">
            Panel Body
            </div>
        </div>
        <div className="panel panel-fixed">
          <div className="panel-head">
            <div className="panel-title">
              Panel Title
              </div>
            <div className="panel-extra">
              <div className={css.search}>
                <div className={`input input-border input-sm ${css.inputdemo}`}>
                  <input type="text" value="input text" />
                </div>
                <button className="btn btn-primary btn-sm">Search</button>
              </div>
              <div className={css.add}>
                <button className="btn btn-primary btn-sm">Add</button>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <ul>
              <li key="1">List 1</li>
              <li key="2">List 2</li>
              <li key="3">List 3</li>
              <li key="4">List 4</li>
            </ul>
          </div>
        </div>

      </div>

    </div>)
  }



}