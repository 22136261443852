import React from 'react'
import {Link} from 'react-router-dom'
import { button } from '../../../six-px/px'
import css from './index.module.scss'


export default class Head extends React.Component {

  render () {
    return (<div className={css.head}>
      <Link className="btn btn-sm btn-outline-primary" to="/en">English</Link>
    </div>)
  }

}