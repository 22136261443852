import React from 'react'
import { button } from '../../../../six-px/px'
import css from './index.module.scss'

export default class Button extends React.Component {

  render() {

    return (<div>
      <h2>按钮</h2>
      <div className={css.buttons}>
        <button className="btn btn-primary btn-xs">超小号 xs 18px</button>
        <button className="btn btn-primary btn-sm">小号 sm 24px</button>
        <button className="btn btn-primary">默认 30px</button>
        <button className="btn btn-primary btn-lg">大号 lg 36px</button>
        <button className="btn btn-primary btn-xl">超大号 xl 42px</button>
      </div>

      <div className={css.buttons}>
        <button className="btn btn-primary">主要</button>
        <button className="btn btn-secondary">次要</button>
        <button className="btn btn-success">成功</button>
        <button className="btn btn-info">信息</button>
        <button className="btn btn-warning">警告</button>
        <button className="btn btn-danger">危险</button>
        <button className="btn btn-light">亮色</button>
        <button className="btn btn-dark">深色</button>
        <button className="btn btn-link">链接</button>
      </div>

      <div className={`${css.buttons} ${css.ghost}`}>
        <button className="btn btn-outline-primary">主要</button>
        <button className="btn btn-outline-secondary">次要</button>
        <button className="btn btn-outline-success">成功</button>
        <button className="btn btn-outline-info">信息</button>
        <button className="btn btn-outline-warning">警告</button>
        <button className="btn btn-outline-danger">危险</button>
        <button className="btn btn-outline-light">亮色</button>
        <button className="btn btn-outline-dark">深色</button>
      </div>

      <div className={`${css.buttons} ${css.ghost}`}>
        <button className="btn btn-outline-primary ghost">主要</button>
        <button className="btn btn-outline-secondary ghost">次要</button>
        <button className="btn btn-outline-success ghost">成功</button>
        <button className="btn btn-outline-info ghost">信息</button>
        <button className="btn btn-outline-warning ghost">警告</button>
        <button className="btn btn-outline-danger ghost">危险</button>
        <button className="btn btn-outline-light ghost">亮色</button>
        <button className="btn btn-outline-dark ghost">深色</button>
      </div>

      <div className={css.buttons}>
        <button className="btn btn-primary disabled">主要</button>
        <button className="btn btn-secondary disabled">次要</button>
        <button className="btn btn-success disabled">成功</button>
        <button className="btn btn-info disabled">信息</button>
        <button className="btn btn-warning disabled">警告</button>
        <button className="btn btn-danger disabled">危险</button>
        <button className="btn btn-light disabled">亮色</button>
        <button className="btn btn-dark disabled">深色</button>
      </div>

      <div className={css.groups}>
        <div className="btn-group btn-group-xs">
          <button className="btn btn-primary">A</button>
          <button className="btn btn-primary">B</button>
          <button className="btn btn-primary">C</button>
        </div>
        <div className="btn-group btn-group-sm">
          <button className="btn btn-primary">A</button>
          <button className="btn btn-primary">B</button>
          <button className="btn btn-primary">C</button>
        </div>
        <div className="btn-group">
          <button className="btn btn-primary">A</button>
          <button className="btn btn-primary">B</button>
          <button className="btn btn-primary">C</button>
        </div>
        <div className="btn-group btn-group-lg" style={{padding: '10px', backgroundColor: '#000'}}>
          <button className="btn btn-outline-primary ghost">A</button>
          <button className="btn btn-outline-primary ghost">B</button>
          <button className="btn btn-outline-primary ghost">C</button>
        </div>
        <div className="btn-group btn-group-lg">
          <button className="btn btn-outline-primary">A</button>
          <button className="btn btn-outline-primary">B</button>
          <button className="btn btn-outline-primary">C</button>
        </div>
        <div className="btn-group btn-group-xl">
          <button className="btn btn-primary">A</button>
          <button className="btn btn-primary">B</button>
          <button className="btn btn-primary">C</button>
        </div>
      </div>

      <div className={css.groups}>
        <div className="btn-group-vertical btn-group-xs">
          <button className="btn btn-primary">A</button>
          <button className="btn btn-primary">B</button>
          <button className="btn btn-primary">C</button>
        </div>
        <div className="btn-group-vertical btn-group-sm">
          <button className="btn btn-primary">A</button>
          <button className="btn btn-primary">B</button>
          <button className="btn btn-primary">C</button>
        </div>
        <div className="btn-group-vertical">
          <button className="btn btn-secondary">A</button>
          <button className="btn btn-secondary">B</button>
          <button className="btn btn-secondary">C</button>
        </div>
        <div className="btn-group-vertical btn-group-md">
          <button className="btn btn-success">A</button>
          <button className="btn btn-success">B</button>
          <button className="btn btn-success">C</button>
        </div>
        <div className="btn-group-vertical btn-group-lg">
          <button className="btn btn-primary">A</button>
          <button className="btn btn-primary">B</button>
          <button className="btn btn-primary">C</button>
        </div>
        <div className="btn-group-vertical btn-group-xl">
          <button className="btn btn-primary">A</button>
          <button className="btn btn-primary">B</button>
          <button className="btn btn-primary">C</button>
        </div>
      </div>
    </div>)

  }

}